// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `\$primary: #20458f;
\$secondary: #0189e3;
\$red: #f25524;
\$red1: #ffdcd1;
\$green: #01e37a;
\$green1: #c6ffe4;
\$gray: #505050;
\$gray3: #b7b7b7;
\$blocked_input: #efefef;
\$border_table: #e5e5e5;
\$border_bottom_header_dropdown: #6f98eb;
\$active_menu: #f09637;
\$border_bottom_select_list_item: #d9d9d9;
\$border_header_table: #707070;
\$border_children_cell_table: #707070;
\$border_input: #bebebe;
\$placeholder_input: #808080;
`, "",{"version":3,"sources":["webpack://./src/src/assets/scss/_color.scss"],"names":[],"mappings":"AAAA,iBAAiB;AACjB,mBAAmB;AACnB,aAAa;AACb,cAAc;AACd,eAAe;AACf,gBAAgB;AAChB,cAAc;AACd,eAAe;AACf,uBAAuB;AACvB,sBAAsB;AACtB,uCAAuC;AACvC,qBAAqB;AACrB,wCAAwC;AACxC,6BAA6B;AAC7B,oCAAoC;AACpC,sBAAsB;AACtB,2BAA2B","sourcesContent":["$primary: #20458f;\n$secondary: #0189e3;\n$red: #f25524;\n$red1: #ffdcd1;\n$green: #01e37a;\n$green1: #c6ffe4;\n$gray: #505050;\n$gray3: #b7b7b7;\n$blocked_input: #efefef;\n$border_table: #e5e5e5;\n$border_bottom_header_dropdown: #6f98eb;\n$active_menu: #f09637;\n$border_bottom_select_list_item: #d9d9d9;\n$border_header_table: #707070;\n$border_children_cell_table: #707070;\n$border_input: #bebebe;\n$placeholder_input: #808080;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
