import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import './src/index.css'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as en from './src/lang/en.json'
import * as fr from './src/lang/fr.json'
import { Spin } from 'antd'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    react: {
      useSuspense: false,
    },
    // the translations
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    lng: 'fr', // if you're using a language detector, do not define the lng option
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
// @ts-ignore
const RootComponent = React.lazy(() => import('./root.component'))

function WrapperComponent(props) {
  return (
    // @ts-ignore
    <Suspense
      fallback={
        <div className="w-screen h-screen flex justify-center items-center">
          {/*// @ts-ignore*/}
          <Spin size="large" />
        </div>
      }
    >
      {/*// @ts-ignore*/}
      <RootComponent {...props} />
    </Suspense>
  )
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: WrapperComponent,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null
  },
})

export const { bootstrap, mount, unmount } = lifecycles
